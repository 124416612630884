export function getEasterSunday(year) {
    var f = Math.floor,
    // Golden Number - 1
    G = year % 19,
    C = f(year / 100),
    // related to Epact
    H = (C - f(C / 4) - f((8 * C + 13) / 25) + 19 * G + 15) % 30,
    // number of days from 21 March to the Paschal full moon
    I = H - f(H / 28) * (1 - f(29 / (H + 1)) * f((21 - G) / 11)),
    // weekday for the Paschal full moon
    J = (year + f(year / 4) + I + 2 - C + f(C / 4)) % 7,
    // number of days from 21 March to the Sunday on or before the Paschal full moon
    L = I - J,
    month = 3 + f((L + 40) / 44),
    day = L + 28 - 31 * f(month / 4);
    if (month < 10) {
        month = `0${month}`;
    }
    if (day < 10) {
        day = `0${day}`;
    }
    return `${year}-${month}-${day}`;
}

export function getMidSummerDay(year) {
    let dt = new Date(`${year}-06-20`);
    return `${year}-06-${20+6-(dt.getDay())}`;
}

export function format (date) {
    return `${date.getFullYear()}-${(date.getMonth()+1)<10 ? "0"+(date.getMonth()+1) : (date.getMonth()+1)}-${(date.getDate())<10 ? "0"+(date.getDate()) : (date.getDate())}`
}

export function getHolidayList(year) {

    let easterDay = getEasterSunday(year);
    let easterDt = new Date(easterDay);
    
    let goodFriday = new Date(easterDt);
    goodFriday.setDate(easterDt.getDate() - 2);

    let easterEv = new Date(easterDt);
    easterEv.setDate(easterDt.getDate() - 1);

    let easterMon = new Date(easterDt);
    easterMon.setDate(easterDt.getDate() + 1);

    let ascensionDay = new Date(easterDt);
    ascensionDay.setDate(easterDt.getDate() + 39);

    let whitsunDay = new Date(easterDt);
    whitsunDay.setDate(easterDt.getDate() + 49);

    let midsummerDay = getMidSummerDay(year);
    let midsummerDt = new Date(midsummerDay);
   
    let midsummerEve = new Date(midsummerDt);
    midsummerEve.setDate((midsummerDt.getDate() - 1));

    let allSaintDay = new Date(midsummerDt);
    allSaintDay.setDate(midsummerDt.getDate() + 133)

    return [
        `${year}-01-01`,
        `${year}-01-06`,
        format(goodFriday),
        format(easterEv),
        format(easterDt),
        format(easterMon),
        `${year}-05-01`,
        format(ascensionDay),
        format(whitsunDay),
        `${year}-06-06`,
        format(midsummerEve),
        format(midsummerDt),
        format(allSaintDay),
        `${year}-12-24`,
        `${year}-12-25`,
        `${year}-12-26`,
        `${year}-12-31`
    ]
}

export function getHolidayList2(year) {    // for notice date calculating..

    let easterDay = getEasterSunday(year);
    let easterDt = new Date(easterDay);
    
    let goodFriday = new Date(easterDt);
    goodFriday.setDate(easterDt.getDate() - 2);

    let easterMon = new Date(easterDt);
    easterMon.setDate(easterDt.getDate() + 1);

    let ascensionDay = new Date(easterDt);
    ascensionDay.setDate(easterDt.getDate() + 39);

    let whitsunDay = new Date(easterDt);
    whitsunDay.setDate(easterDt.getDate() + 49);

    let midsummerDay = getMidSummerDay(year);
    let midsummerDt = new Date(midsummerDay);
   
    let midsummerEve = new Date(midsummerDt);
    midsummerEve.setDate((midsummerDt.getDate() - 1));

    let allSaintDay = new Date(midsummerDt);
    allSaintDay.setDate(midsummerDt.getDate() + 133)

    return [
        `${year}-01-01`,
        `${year}-01-06`,
        format(goodFriday),
        format(easterDt),
        format(easterMon),
        `${year}-05-01`,
        format(ascensionDay),
        format(whitsunDay),
        `${year}-06-06`,
        format(midsummerDt),
        format(allSaintDay),
        `${year}-12-25`,
        `${year}-12-26`,
        `${year}-12-31`
    ]
}

export function getMidSummerEve(year) {

    let midsummerDay = getMidSummerDay(year);
    let midsummerDt = new Date(midsummerDay);
   
    let midsummerEve = new Date(midsummerDt);
    midsummerEve.setDate((midsummerDt.getDate() - 1));

    return format(midsummerEve);
}

export function getgoodFriday(year) {

   let easterDay = getEasterSunday(year);
    let easterDt = new Date(easterDay);
    
    let goodFriday = new Date(easterDt);
    goodFriday.setDate(easterDt.getDate() - 2);

    return format(goodFriday);
}




export function validate (str) {
    
    let list = str.split('-');
    if (list.length != 3) {
        return false;
    } else {
        if (Number.isInteger(Number(list[0])) && Number.isInteger(Number(list[1])) && Number.isInteger(Number(list[2]))) {
            
            if (Number(list[1]) > 0 && Number(list[1]) <= 12 && Number(list[2]) > 0 && Number(list[2]) < 32) {
                if (Number(list[1]) == 4 || Number(list[1]) == 6 || Number(list[1]) == 9 || Number(list[1]) == 11) {
                    if (Number(list[2]) >= 31) {
                        return false;
                    } else {
                        return true;
                    }
                } else if (Number(list[1]) == 2) {
                    let isLunar = (Number(list[0]) % 4 == 0);
                    if (isLunar) {
                        if (Number(list[2]) >= 30) {
                            return false;
                        } else {
                            return true;
                        }
                    } else {
                        if (Number(list[2]) >= 29) {
                            return false;
                        } else {
                            return true;
                        }
                    }
                }
            } else {
                return false;
            }

        } else {
            return false;
        }
    }

}

export function swedishMonthStr(mon) {
    switch(mon) {
        case 1: {
            return 'januari'
        }
        case 2: {
            return 'februari'
        }
        case 3: {
            return 'mars'
        }
        case 4: {
            return 'april'
        }
        case 5: {
            return 'maj'
        }
        case 6: {
            return 'juni'
        }
        case 7: {
            return 'juli'
        }
        case 8: {
            return 'augusti'
        }
        case 9: {
            return 'september'
        }
        case 10: {
            return 'oktober'
        }
        case 11: {
            return 'november'
        }
        case 12: {
            return 'december'
        }
    }
}

export function formatOutput(str) {
    let list = str.split('-');
    let year = list[0];
    let mon = swedishMonthStr(Number(list[1]));
    let day = Number(list[2]);
    return `${day} ${mon} ${year}`;
}

