import toast, { Toaster } from 'react-hot-toast';
import './App.css';
import { useState } from 'react';
import { getHolidayList, format, validate, formatOutput, getHolidayList1, getHolidayList2, getMidSummerEve, getgoodFriday } from './utils';

const notify = () => toast.error("Skriv datum i formatet ÅÅÅÅ-MM-DD");

function App() {

  const [meetingDate, setMeetingDate] = useState();

  const [show, setShow] = useState(false);

  const [date1, setDate1] = useState('');
  const [date2, setDate2] = useState('');
  const [date3, setDate3] = useState('');
  const [date4, setDate4] = useState('');
  const [date5, setDate5] = useState('');
  const [date6, setDate6] = useState('');
  const [date7, setDate7] = useState('');
  const [date8, setDate8] = useState('');

  const calc = () => {

    let result = validate(meetingDate);
    let year = meetingDate.split('-')[0];

    if (result == false) {
      notify();
      setMeetingDate('');
      return;
    }

    let list = getHolidayList(Number(year));

    // get summon date:: 4 weeks before from meeting day...
    let meetingDt = new Date(meetingDate);
    let summonDate = new Date(meetingDt);
    summonDate.setDate(summonDate.getDate() - 28);

    // get Record date: 6 days before from meeting day...
    let count = 0;
    let tmpDt = new Date(meetingDt);
    while (1) {
      let dt = new Date(tmpDt);
      dt.setDate(dt.getDate() - 1);

      list = getHolidayList(dt.getFullYear())

      if (dt.getDay() != 0 && dt.getDay() != 6) {

        let dt_str = format(dt);
        let flag = 0;
        list.forEach(ele => {
          if (ele == dt_str) {
            flag = 1;
          }
        })
        if (flag == 1) {
          tmpDt = new Date(dt);
        } else {
          tmpDt = new Date(dt);
          count += 1;
        }
      } else {
        tmpDt = new Date(dt);
      }

      if (count >= 6) break;
    }

    let recordDate = new Date(tmpDt);

    // get Registration date: 4 days before from meeting day...
    count = 0;
    console.log(`meetingDt1::`, meetingDt);
    tmpDt = new Date(meetingDt);
    while (1) {
      let dt = new Date(tmpDt);
      dt.setDate(dt.getDate() - 1);

      list = getHolidayList(dt.getFullYear())

      if (dt.getDay() != 0 && dt.getDay() != 6) {

        let dt_str = format(dt);
        let flag = 0;
        list.forEach(ele => {
          if (ele == dt_str) {
            flag = 1;
          }
        })
        if (flag == 1) {
          tmpDt = new Date(dt);
        } else {
          tmpDt = new Date(dt);
          count += 1;
        }
      } else {
        tmpDt = new Date(dt);
      }

      if (count >= 4) break;
    }

    let registrationDate = new Date(tmpDt);


 // get SvD date: 3 days before from meeting day...
    count = 0;
    tmpDt = new Date(summonDate);
    while (1) {
      let dt = new Date(tmpDt);
      dt.setDate(dt.getDate() - 1);

      list = getHolidayList(dt.getFullYear())

      if (dt.getDay() != 0 && dt.getDay() != 6) {

        let dt_str = format(dt);
        let flag = 0;
        list.forEach(ele => {
          if (ele == dt_str) {
            flag = 1;
          }
        })
        if (flag == 1) {
          tmpDt = new Date(dt);
        } else {
          tmpDt = new Date(dt);
          count += 1;
        }
      } else {
        tmpDt = new Date(dt);
      }

      if (count >= 3) break;
    }

    let svdDate = new Date(tmpDt);



    // get Poit date: 2 days before from meeting day...
    count = 0;
    tmpDt = new Date(summonDate);
    while (1) {
      let dt = new Date(tmpDt);
      dt.setDate(dt.getDate() - 1);

      list = getHolidayList(dt.getFullYear())

      if (dt.getDay() != 0 && dt.getDay() != 6) {

        let dt_str = format(dt);
        let flag = 0;
        list.forEach(ele => {
          if (ele == dt_str) {
            flag = 1;
          }
        })
        if (flag == 1) {
          tmpDt = new Date(dt);
        } else {
          tmpDt = new Date(dt);
          count += 1;
        }
      } else {
        tmpDt = new Date(dt);
      }

      if (count >= 2) break;
    }

    let poitDate = new Date(tmpDt);


        // get annual report date:: 3 weeks before from meeting day...
    let annualreportDate = new Date(meetingDt);
    annualreportDate.setDate(annualreportDate.getDate() - 21);


     // get proposal report date:: 2 weeks before from meeting day...
    let proposalDate = new Date(meetingDt);
    proposalDate.setDate(proposalDate.getDate() - 14);




    // get notice date: 5 days before from meeting day...

    count = 0;
    console.log(`meetingDt::`, meetingDt);
    tmpDt = new Date(meetingDt);

    while (1) {
      let dt = new Date(tmpDt);
      dt.setDate(dt.getDate() - 1);
      list = getHolidayList2(dt.getFullYear())

      if (dt.getDay() != 0) {

        let dt_str = format(dt);
        let flag = 0;
        list.forEach(ele => {
          if (ele == dt_str) {
            flag = 1;
          }
        })
        if (flag == 1) {
          tmpDt = new Date(dt);
        } else {
          tmpDt = new Date(dt);
          count += 1;
        }
      } else {
        tmpDt = new Date(dt);
      }
      // make sure Notice Day does not fall on Saturday, Sunday or Holiday...

      let tmpDtStr = format(tmpDt);
      let midSummerEve = getMidSummerEve(year);
      let goodFriday = getgoodFriday(year);
      if (count >= 5 && !(tmpDt.getDay() == 6 || tmpDt.getDay() == 0 ||tmpDtStr == `${year}-01-01`||tmpDtStr == `${year}-01-06`||tmpDtStr == `${year}-05-01`||tmpDtStr == `${year}-06-06`||tmpDtStr == `${year}-12-31` || tmpDtStr == `${year}-12-24` || tmpDtStr == `${year}-12-26`|| tmpDtStr == midSummerEve || tmpDtStr == goodFriday)) break;
    }
    

    let noticeDate = new Date(tmpDt);


    setDate1(formatOutput(format(summonDate)))
    setDate2(formatOutput(format(recordDate)))
    setDate3(formatOutput(format(registrationDate)))
    setDate4(formatOutput(format(noticeDate)))
    setDate5(formatOutput(format(poitDate)))
    setDate6(formatOutput(format(annualreportDate)))
    setDate7(formatOutput(format(proposalDate)))
    setDate8(formatOutput(format(svdDate)))

    setShow(true);
  }

  return (
    <div className="flex flex-col items-center gap-y-[35px]">

      <span className='text-[35px] lg:text-[50px] mb-[10px] text-center mt-[20px] lg:mt-[75px]'>Beräkna avstämningsdag</span>

      <div className="flex flex-col gap-y-[10px] w-[90%] lg:w-[800px]">
        <span className="text-[15px] lg:text-[15px]">Här kan du räkna ut avstämningsdag, sista dag för anmälan, rösträttsregistrering och andra relevanta datum inför bolagsstämma i ett publikt aktiebolag. Mer info om reglerna finns <span className='text-[15px] text-[#0434EC]'><a href="https://avstämningsdag.se/reglerna" target="_blank">här</a></span>.
        </span>

      </div>

      <div className="flex flex-col gap-y-[10px] w-[90%] lg:w-[800px]">
        <span className="text-[15px] lg:text-[15px]">Den här tjänsten tillhandahålls kostnadsfritt av <span className='text-[15px] text-[#0434EC]'><a href="https://postrosta.se" target="_blank">Poströsta.se</a></span>, en plattform för helt digital förhandsröstning och anmälan för fysisk närvaro. Hundratals noterade bolag som Kinnevik, Storytel och Avanza har använt vår plattform för att både de själva och deras aktieägare ska slippa hantera utskrifter och scannade pdf-filer. Testa inför nästa stämma, du kommer inte ångra dig!</span>

      </div>

      <div className="flex flex-row gap-x-[10px]">
        <span className="text-[18px] lg:text-[20px] text-center">Ange stämmodatum (ÅÅÅÅ-MM-DD)</span>
        <input type="text" className='outline-none px-[5px] bg-transparent border border-gray-500 rounded-md w-[130px]' value={meetingDate} onChange={(evt) => { setMeetingDate(evt.target.value) }} />
      </div>
      <div className="flex flex-row">
        <div className="custom-btn bg-[#1742ef] rounded-lg px-[40px] py-[10px] cursor-pointer roun" onClick={calc}>
          <span className='text-[18px] text-[#FFFFFFD0]'>Beräkna datum</span>
        </div>
      </div>

      {
        show &&
        <div className="flex flex-col gap-y-[15px]">
          <div className="flex flex-col items-center w-full">
            <div className="flex flex-col gap-y-[15px] w-[90%] lg:w-[800px]">
              <div className="flex flex-row gap-x-[10px] items-center">
                <span className="text-[16px] lg:text-[18px] ">{'Relevanta datum:'}</span>
              </div>

              <div className="flex flex-row gap-x-[20px] items-center">
                <span className="text-[12px] lg:text-[15px] w-[40%] lg:w-[280px]">{'Stämmodag:'}</span>
                <span className="text-[12px] lg:text-[15px] w-[40%] lg:w-[350px]">{''}</span>
                <span className="text-[12px] lg:text-[15px] ">{`${formatOutput(meetingDate)}`}</span>
              </div>

              <div className="flex flex-row gap-x-[20px] items-center">
                <span className="text-[12px] lg:text-[15px] w-[40%] lg:w-[280px]">{'Sista dag för kallelse'}</span>
                <span className="text-[12px] lg:text-[15px] w-[40%] lg:w-[350px]">{'Fyra veckor innan årsstämma (ABL 7:18)'}</span>
                <span className="text-[12px] lg:text-[15px] ">{`${date1}`}</span>
              </div>

                 <div className="flex flex-row gap-x-[20px] gap-y-[10px] items-center">
                <span className="text-[12px] lg:text-[15px] w-[40%] lg:w-[280px]">{'Manusstopp SvD:'}</span>
                <span className="text-[12px] lg:text-[15px] w-[40%] lg:w-[350px]">{'Tre vardagar innan kallelse kl 16.00*'}</span>
                <span className="text-[12px] lg:text-[15px] ">{`${date8}`}</span>
              </div>

               <div className="flex flex-row gap-x-[20px] gap-y-[10px] items-center">
                <span className="text-[12px] lg:text-[15px] w-[40%] lg:w-[280px]">{'Manusstopp Post- och inrikes:'}</span>
                <span className="text-[12px] lg:text-[15px] w-[40%] lg:w-[350px]">{'Två vardagar innan kallelse kl 23.59'}</span>
                <span className="text-[12px] lg:text-[15px] ">{`${date5}`}</span>
              </div>

                 <div className="flex flex-row gap-x-[20px] gap-y-[10px] items-center">
                <span className="text-[12px] lg:text-[15px] w-[40%] lg:w-[280px]">{'Manusstopp Dagens Industri:'}</span>
                <span className="text-[12px] lg:text-[15px] w-[40%] lg:w-[350px]">{'Två vardagar innan kallelse kl 09.00'}</span>
                <span className="text-[12px] lg:text-[15px] ">{`${date5}`}</span>
              </div>

               <div className="flex flex-row gap-x-[20px] gap-y-[10px] items-center">
                <span className="text-[12px] lg:text-[15px] w-[40%] lg:w-[280px]">{'Publicering ÅR:'}</span>
                <span className="text-[12px] lg:text-[15px] w-[40%] lg:w-[350px]">{'Tre veckor innan stämma (ABL 7:56b)'}</span>
                <span className="text-[12px] lg:text-[15px] ">{`${date6}`}</span>
              </div>


              <div className="flex flex-row gap-x-[20px] gap-y-[10px] items-center">
                <span className="text-[12px] lg:text-[15px] w-[40%] lg:w-[280px]">{'Fullständiga förslag:'}</span>
                <span className="text-[12px] lg:text-[15px] w-[40%] lg:w-[350px]">{'Två veckor innan stämma (t.ex. ABL 13:9)'}</span>
                <span className="text-[12px] lg:text-[15px] ">{`${date7}`}</span>
              </div>

              <div className="flex flex-row gap-x-[20px] items-center">
                <span className="text-[12px] lg:text-[15px] w-[40%] lg:w-[280px]">{'Avstämningsdag:'}</span>
                <span className="text-[12px] lg:text-[15px] w-[40%] lg:w-[350px]">{'Sex bankdagar innan stämma (ABL 7:28)'}</span>
                <span className="text-[12px] lg:text-[15px] ">{`${date2}`}</span>
              </div>

              <div className="flex flex-row gap-x-[20px] items-center">
                <span className="text-[12px] lg:text-[15px] w-[40%] lg:w-[280px]">{'Sista dag för rösträttsregistrering:'}</span>
                <span className="text-[12px] lg:text-[15px] w-[40%] lg:w-[350px]">{'Fyra bankdagar innan stämma (ABL 7:28)'}</span>
                <span className="text-[12px] lg:text-[15px] ">{`${date3}`}</span>
              </div>

              <div className="flex flex-row gap-x-[20px] gap-y-[10px] items-center">
                <span className="text-[12px] lg:text-[15px] w-[40%] lg:w-[280px]">{'Sista dag för anmälan:'}</span>
                <span className="text-[12px] lg:text-[15px] w-[40%] lg:w-[350px]">{'Fem vardagar ink. lördag innan stämma (ABL 7:2)'}</span>
                <span className="text-[12px] lg:text-[15px] ">{`${date4}`}</span>
              </div>
                  <span className="text-[10px]">*SvD har ibland egna undantag för publicering kring helgdagar. De leder oftast till längre deadline, men kolla med dem för säkerhets skull.</span>
            </div>
          </div>
          <div className="flex flex-col gap-y-[10px] items-center w-full">
            <div className="flex flex-col gap-y-[10px] w-[90%] lg:w-[800px]">
              <span className="text-[18px]">
                Text till kallelse/notis:
              </span>
              <span className="text-[15px]">
                {`Aktieägarna i [Bolaget, org. nr XX], kallas till årsstämma den ${formatOutput(meetingDate)} kl. [XX.XX] på [adress]. Ägarna kan välja mellan att delta fysiskt i stämmolokalen eller att skicka in sina röster i förväg genom s.k. poströstning.`}
              </span>
              <span className="text-[15px]">
                {`Aktieägare som önskar rösta ska vara införda i den av Euroclear Sweden AB förda aktieboken per avstämningsdagen den ${date2} och senast den ${date4} antingen anmäla sig för fysisk närvaro eller skicka in sina röster genom ett digitalt poströstningsformulär som finns tillgängligt på bolagets webbplats, allt enligt instruktion i kallelsen.`}
              </span>
              <span className="text-[15px]">
                {`Aktieägare med förvaltarregistrerade aktier måste tillfälligt låta omregistrera aktierna i eget namn per avstämningsdagen den ${date2} och kontakt bör tas med banken/förvaltaren i god tid innan. Förvaltaren har möjlighet att göra sådan rösträttsregistrering retroaktivt, dock senast den ${date3} för att den ska beaktas vid framställning av aktieboken.`}
              </span>
            </div>
          </div>

          <span className="text-[16px]">




          </span>
        </div>
      }

      <Toaster />

    </div>
  );
}

export default App;
